.menuImage {
    height: 300px;
    width: 300px;
    border: 1px solid lightgray;
    border-radius: 10px;
    @apply object-cover;
}

.menuImage:hover {
    opacity: 0.75 !important;
}

.textDecoration {
    text-decoration: none;
    width: max-content;
    cursor: pointer;
}