.title {
    color: #657695;
}

/* .topHeading {
    color: #eb5646;
} */

.paragraph > p {
    color: #000;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
}
.darkMode > * {
    color: white!important;
}

/* .gridStyleImage {
  max-width: fit-content;
} */

.imageStyle {
    height: fit-content;
}

