.textAsset {
    max-height: 322px;
    min-width: 450px;
}

@media screen and (max-width: 768px) {
    .textAsset {
    
      min-width: auto
    }
    
  }