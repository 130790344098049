.heading {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #fff;
}

.lists {
    width: 150px;
}

.subLinks {
    text-decoration: none;
    font-size: 14px;
    color: #000 !important;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
}

.footerBlock {
    color: #150a61!important;
}

.copyrightText,
.copyrightText > p {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    color: #fff;
}
.warning {
    color: #E85642;
}

@media (max-width: 767px) {
    .footerBlock {
        margin: 16px;
    }

    .input {
        margin-right: 16px;
    }
}
