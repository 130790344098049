.sponsorImage {
    /* max-width: 500px;
    max-height: 400px; */
    border: 1px solid lightgray;
    border-radius: 10px;
}
.sponsorImage:hover {
    opacity: 0.75 !important;
}

.icon {
    width:16.71px  !important;
    color: #A9B6CB !important;
}

@media screen and (max-width: 767px) {
    .sponsorImage {
        max-width: 100%;
        max-height: auto;
        margin: auto;
    }
}