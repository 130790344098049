.textMultiImageDark .bgColor {
  background-color: #4b459b;
}

.textMultiImageDark a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  text-decoration: none;
}

.textMultiImageDark h1 {
  color: #fff;
  font-family: Inter, sans-serif;
}



@media only screen and (min-width: 575px) and (max-width: 767px) {
    .primaryImage {
        width: 9rem;
        height: 14rem;
      }

      .imageContainer{
        left: 45%
    }

     .textContainer {
        width: 42%;
      }
  
  }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .textContainer {
    width: 40%;
  }

  .imageContainer{
      left: 45%
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .primaryImage {
    width: 10rem;
    height: 12rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .primaryImage {
    width: 12rem;
    height: 13rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .primaryImage {
      width: 15rem;
      height: 15rem;
    }

   
  }
