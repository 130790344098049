.textCta {
  @apply mt-24;
}

.textCta.darkMode
.bgColor {
  background: #4B459B;
}

.textCta.darkMode
.bgColor h2,
.textCta.darkMode
.bgColor p,
.textCta.darkMode
.bgColor a {
  @apply text-white;
}

.textCta.darkMode
.bgColor a {
 font-weight: 600;
}

.textCta.lightMode
.bgColor h2,
.textCta.lightMode
.bgColor p {
  @apply text-black;
}


.button {
  height: 57px;
}

.button > a {
  line-height: 19.36px;
}
