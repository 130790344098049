.shine,.line,.word {
    background-image: linear-gradient(to right, #ebebeb calc(50% - 100px), #c5c5c5 50%, #ebebeb calc(50% + 100px));
    background-size:0;
    height: 450px;
    border-radius: 10px;
    position:relative;
    overflow:hidden;
  }
  .shine::before,.line::before,.word::before {
    content:"";
    position:absolute;
    top:0;
    right:0;
    width:calc(200% + 200px);
    bottom:0;
    background-image:inherit;
    animation:move 2s linear infinite; 
  }
  .line {
    height: 20px;
    margin-bottom: 12px;
  }
  .word {
    height: 20px;
    width: 25%;
    margin-bottom: 12px;
  }
  @keyframes move{
    to {
      transform:translateX(calc(50% + 100px));
    }
  }