.productName {
    line-height: 10px !important;
    font-size: 13px;
}



@media screen and (max-width: 991px) and (min-width: 767px) {
    .gridView{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}