.textAsset {
  max-height: 322px;
  min-width: 450px;
}

/* .gridStyleImage {
  max-width: fit-content;
} */

.greybackgroundGradient {
  background-image: linear-gradient(
      180deg,
      rgba(168, 183, 203, 0.25) 0%,
      rgba(221, 227, 238, 0.25) 57.81%,
      rgba(255, 255, 255, 0.25) 100%
    );
}

.imageCardXlBlock + div + div + h2 {
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.0001em;
  text-align: center;

}


@media screen and (max-width: 768px) {
  .textAsset {
  
    min-width: auto
  }
  
}