.headerHeight {
	min-height: 32px;
    background-color:#150a61;
}

.ctaSection,.select {
    text-decoration: none;
    font-family: Inter, sans-serif; 
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    color: #fff !important;
    letter-spacing: 0;
    text-align: left;
}

.select {
    border: none;
    cursor: pointer;
}
.select:focus {
    box-shadow: none;
}