.logo {
    height: 90px;
    position: relative;
    top: -14px;
    /* margin-top: 4px; */
}

.anchor {
    color: #000 !important;
    font-size: 16px;
    font-weight: 500;
}
.textDecoration {
    text-decoration: none;
    width: max-content;
    cursor: pointer;
}

.active {
    text-decoration: none;
    color: orangered !important;
}
.activeLink {
    text-decoration: underline;
    color: orangered !important;
}

.focusBtn:focus {
    outline: none !important;
}

.productList {
    padding: 0 32px 16px 32px;
}

.menuImage {
    height: 300px;
    width: 300px;
    margin: auto;
    border: 1px solid lightgray;
    border-radius: 10px;
    @apply object-cover;
}

.marginCss {
    margin-left: -120px
}

.borderStyle {
    border-right: 1px solid lightgray;
}

.menuImage + p {
    width: fit-content;
    margin: auto;
}

.menuImage:hover {
    opacity: 0.75 !important;
}

.ul:first-child {
    display: flex !important;
}

@media screen and (max-width: 991px) {
    .productList {
        padding: 0;
    }
    .productList > li {
        padding-left: 0;
    }
    .linkList > li {
        padding-left: 0;
    }
    .logo {
        height: 70px;
    }
}

@media screen and (max-width: 767px) {
    .marginCss {
        margin-left: 0;
    }
    ul {
        flex-direction: column;
    }
    .logo {
        height: 85px;
        top: 0;
    }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
    .logo {
        height: 75px;
        top: -4px;
    }
}