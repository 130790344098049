.h3 {
    color: #150a61 !important;
    z-index: 1;
}

.paragraph >image {
    margin: auto;
}
.opacity:hover {
    opacity: 0.75 !important;
}

@media only screen and (max-width: 768px) {
    .imageContainer {
        flex-basis: 29% !important;
    }
}

@media only screen and (max-width: 992px) {
    .imageContainer {
        flex-basis: 29% !important;
    }
}

@media only screen and (max-width: 575px) {
    .imageContainer {
        flex-basis: 80% !important;
    }
}