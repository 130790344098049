@font-face {
    font-family: Inter;
    font-display: swap;
    src: url('../assets/font/Inter-Regular.ttf');
}

@font-face {
    font-family: Inter-Bold;
    font-display: swap;
    src: url('../assets/font/Inter-Bold.ttf');
}

@font-face {
    font-family: Inter-ExtraBold;
    font-display: swap;
    src: url('../assets/font/Inter-ExtraBold.ttf');
}

@font-face {
    font-family: Inter-SemiBold;
    font-display: swap;
    src: url('../assets/font/Inter-SemiBold.ttf');
}

@font-face {
    font-family: Inter-Thin;
    font-display: swap;
    src: url('../assets/font/Inter-Thin.ttf');
}

@font-face {
    font-family: Montserrat-Bold;
    font-display: swap;
    src: url('../assets/font/Montserrat-Bold.ttf');
}

@font-face {
    font-family: Montserrat;
    font-display: swap;
    src: url('../assets/font/Montserrat-Regular.ttf');
}
