.paragraph {
    /* max-width: 608px; */
    /* margin: 10px auto; */
}
.paragraph > p,
.paragraph {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24.5px;
    /* text-align: center; */
    /* color: #FFFFFF !important; */
}

.button {
  height: 57px;
}

.button > a {
  line-height: 19.36px;
}

.background {
 background: lightgray;
}
.textBlue {
  color: rgb(9, 9, 129);
}




@media only screen and (min-width: 360px) and (max-width: 767px) {
  .paragraph {
    width: 85%;
  }

  .overlay {
    top:0 ;
    left: 0;
    right: 0 ;
    bottom: auto;
    height: -webkit-fill-available;
  }
}
