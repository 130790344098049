/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable at-rule-no-vendor-prefix */
/* stylelint-disable selector-class-pattern */

/* html,
body {
	overflow-x: hidden;
} */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	.productSection {
		@apply py-10;
	}

	.productHeading {
		margin-bottom: 10px;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	div,
	li {
		color: #000;
		font-family: Inter, sans-serif;
	}

	h1 {
		@apply font-bold;
		font-size: 42px;
		line-height: 50.4px;
		font-family: Montserrat-Bold, sans-serif;
	}

	h2 {
		@apply font-bold;
		font-size: 36px;
		line-height: 43.2px;
		font-family: Montserrat-Bold, sans-serif;
	}

	h3 {
		@apply font-extrabold;
		font-size: 26px;
		line-height: 31.2px;
		color: #E85646;
	}

	h4 {
		@apply font-extrabold;
		font-size: 22px;
		line-height: 26.4px;
	}

	h5 {
		@apply font-bold;
		font-size: 24px;
		line-height: 28.8px;
	}

	h6 {
		@apply text-base font-semibold uppercase tracking-wide;
		color: #6D6D6D;
	}

	p {
		@apply text-sm font-normal;
		line-height: 24.5px;
		font-family: Inter, sans-serif;
	}

	p.leadParagraph {
		@apply text-base font-semibold leading-7;
	}

	a {
		@apply text-sm font-normal underline;
		color: #6C5CE7;
		line-height: 24.5px;
		font-family: Inter, sans-serif;
	}

	a:visited {
		color: #4B459B;
	}

	a:hover,
	a:active {
		color: #E85646;
	}
}

.container {
	@apply relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

button:focus-visible {
	outline: none;
}

.onlyFadeIn {
	-webkit-animation: onlyfadein 1s;
	animation: onlyfadein 1s;
}

.TextVideo_vimeo__2pmni > iframe  {
	outline: none !important;
}



@-webkit-keyframes onlyfadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes onlyfadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@layer components {
	a.btn {
		@apply inline-flex items-center justify-center px-4 py-3 border border-transparent text-base
			font-bold shadow-sm sm:px-8 no-underline uppercase;
		min-width: 70px;
		min-height: 44.03px;
		border-radius: 22px;
	}

	button.slick-prev::before {
		color: #000;
	}

	button.slick-next::before {
		color: #000;
	}
}

a.btnPrimary,
a.primary {
	@apply text-white;
	background: #150a61;
}

a.btnPrimary:hover,
a.primary:hover {
	background:  transparent;
	border: 2px solid #150a61;
	border-radius: 22px;
	color: #150a61;
}

a.btnPrimaryOutline {
background-color: transparent;
	color: #150a61;
	border: 2px solid #150a61;
}

a.btnPrimaryOutline:hover {
	background-color: #150a61;
	color: #fff;
	border: 2px solid #150a61;
}

a.btnSecondary,
a.secondary {
	@apply text-white;
	background: #E85646;
}

a.btnSecondary:hover,
a.secondary:hover {
	background: #BB2817;
}

a.btnSecondaryOutline {
	@apply bg-white;
	color: #E85646;
	border: 2px solid #E85646;
}

a.btnSecondaryOutline:hover {
	@apply bg-white;
	color: #BB2817;
	border: 2px solid #BB2817;
}

.pagination {
	@apply border-t border-gray-200 px-4 flex justify-center items-center sm:px-0 relative flex-row;
}

.pagination .previousArrow a,
.pagination .nextArrow a {
	@apply border-t-2 border-transparent pt-4 pr-1 flex items-center text-sm font-medium
		text-gray-500 hover:text-gray-700 hover:border-gray-300 no-underline;
}

.pagination .previousArrow,
.pagination .nextArrow {
	@apply absolute top-0;
}

.pagination .pageItem a {
	@apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4
		inline-flex items-center text-sm font-medium no-underline;
}

.pagination .previousArrow.disabled a,
.pagination .nextArrow.disabled a {
	@apply pointer-events-none opacity-75;
}

.pagination .previousArrow {
	@apply left-0;
}

.pagination .nextArrow {
	@apply right-0;
}

.pagination .pageItem.activePage a {
	@apply border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium;
}

.crt-panel-prev svg {
	margin-left: 14px !important;
}

.crt-panel-next svg {
	margin-left: 18px !important;
}

.crt-panel-prev svg:hover,
.crt-panel-next svg:hover {
	opacity: 0.75;
}

.lazy-load-image-loaded {
	display: contents !important;
}

.player .vp-title .vp-title-header {
	display: none !important;
}

.vp-title-header {
	display: none !important;
}

.vp-title .byline-from{
	display: none  !important;
}

footer {
	background-color: #150a61;
	color: #fff;
}

@media only screen  and (max-width: 1024px) {
	h1 {
		font-size: 36px;
		line-height: 44.4px;
	}

	h2 {
		font-size: 32px;
		line-height: 37.2px;
	}

	.lazy-load-image-loaded {
		width: 100% !important;
	}
}

@media only screen  and (max-width: 767px) {
	h1 {
		font-size: 30px;
		line-height: 39.4px;
	}

	h2 {
		font-size: 28px;
		line-height: 34.2px;
	}

	.lazy-load-image-loaded {
		width: 100% !important;
	}
}

@media screen and (max-width: 450px) {
	.pageLink {
		padding-right: 0 !important;
	}
}
